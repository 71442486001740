var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(Object.assign({}, {display: 'flex',
    /* borderBottom: '1px solid #f4f2f2', */
    padding: '3px',
    alignItems: 'center'},

    (_vm.fullWidth
      ? {
          width: '100%'
        }
      : {})))},[_c('b-field',{style:(Object.assign({}, (_vm.fullWidth
        ? {
            width: '100%'
          }
        : {}))),attrs:{"label":_vm.label}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }