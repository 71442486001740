var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContentLayoutOnly',{attrs:{"role":"region","workorder-promote":""}},[_c('section',{staticClass:"section card"},[_c('div',{staticClass:"level pl-5 pr-5"},[_c('div',{staticClass:"pt-5 level-left level-item title"},[_vm._v(_vm._s(_vm.$t('workorderrequest.title')))]),_c('div',{staticClass:"level-right",style:(Object.assign({}, (_vm.breakpoint === 'desktop'
            ? {}
            : {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start'
              })))},[_c('div',{staticClass:"level-item",style:(Object.assign({}, (_vm.breakpoint === 'desktop'
              ? {}
              : {
                  margin: 0
                })))},[_c('svg',{attrs:{"width":"30","height":"30","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 19l-7-7 7-7"}})])]),_c('div',{staticClass:"level-item"},[_c('b-button',{attrs:{"label":"Back to Work Order Requests"},on:{"click":function($event){$event.preventDefault();return _vm.returnToParent()}}})],1)])]),_c('LinksMenu',{style:({
        paddingLeft: '20px'
      }),attrs:{"aria-label":"work order details tabs","tabs":_vm.tabs,"prefix":"workorderrequest.","params":{
        id: _vm.$route.params.id
      }}},[_c('section',{style:({ overflowX: 'auto', flexGrow: 2 })},[_c('SlideTransition',{attrs:{"name":_vm.transitionName}},[_c('keep-alive',[_c('router-view',{key:_vm.$route.name})],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }