import _get from 'lodash/get'
import { getWorkOrderTypes } from '@/services/WorkOrders/WorkOrderType/List'
import { getWorkOrderRequest } from '@/services/WorkOrderRequests/WorkOrderRequest/Get'
import { deleteWorkOrderRequestPhoto } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/Delete'
import { editWorkOrderRequest } from '@/services/WorkOrderRequests/WorkOrderRequest/Put'
import { GetMultiplePhotos } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/procedures/GetMultiplePhotos'
import { StoreMultiplePhotos } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/procedures/StoreMultiplePhotos'
import { workOrderList } from '@/services/WorkOrders/store'
import { getOwner } from '@/services/Roster/Owner/getOwner'
import { notifyProblem } from '@/services/notify'
import { hoaUtility } from '@/services/Hoa/store'
import _orderBy from 'lodash/orderBy'
import $ from 'jquery'

export const methods = {
  async saveEdits() {
    this.loading = true

    const description = this.$refs.description.getValue()
    const category = this.category.value ? this.category.value : null
    const summary = this.$refs.summary.getValue()
    const locationType = this.locationType ? this.locationType : null
    let latitude = $('#formLatitude')
      ? $('#formLatitude').text()
      : this.latitude
      ? this.latitude
      : null
    let longitude = $('#formLongitude')
      ? $('#formLongitude').text()
      : this.longitude
      ? this.longitude
      : null
    const { hoaID, sourceUnitID } = await this.getParams()

    const submitterName = _get(this, ['request', 'submitterName'], null)
    const submitterType = _get(this, ['request', 'submitterType'], null)
    const status = _get(this, ['request', 'status'], null)
    const workOrderTypeID = category
    const workOrderRequestID = _get(this, ['request', 'workOrderRequestID'], null)
    const submitterAspNetUserID = 'temp'

    if (!latitude || latitude === 0) {
      latitude = _get(this, ['request', 'latitude'], 0)
    }

    if (!longitude || longitude === 0) {
      longitude = _get(this, ['request', 'longitude'], 0)
    }

    const { message, successful } = await editWorkOrderRequest({
      json: {
        workOrderRequestID,
        hoaID,
        sourceUnitID,
        workOrderTypeID,
        description: summary,
        detailedDescription: description,
        status,
        submitterName,
        submitterType,
        submitterAspNetUserID,
        locationType,
        latitude,
        longitude
      }
    })
    if (!successful) {
      console.error(message)
      return
    }

    this.summary = summary
    this.description = description
    this.category = this.category.label
    this.workOrderTypeID = this.category.value ? this.category.value : this.workOrderTypeID
    this.workOrderType = this.category.label ? this.category.label : this.workOrderType

    this.editMode = false
    this.loading = false
  },

  async addPhotos() {
    this.photoModalLoading = true

    const id = _get(this, ['$route', 'params', 'id'], '')

    const { problems, message } = await StoreMultiplePhotos({
      photos: this.adderPhotos,
      params: {
        workOrderRequestID: id
      }
    })
    if (problems >= 1) {
      notifyProblem(message)
    }

    await this.refresh()

    this.photoModalOpen = false
    this.photoModalLoading = false
  },

  async adderPhotosUpdated({ items }) {
    this.adderPhotos = items
  },

  async toggleEdit() {
    if (this.editMode === false) {
      await this.getCategories()

      console.debug('editMode=' + this.editMode)

      if (this.workOrderType && this.workOrderTypeID > 0 && this.editMode === false) {
        this.category = {
          label: this.workOrderType || '',
          value: this.workOrderTypeID || 0
        }
      }

      this.description = _get(this, ['request', 'detailedDescription'], '')
    }

    this.editMode = !this.editMode
  },

  locationTypeChanged() {
    if (this.locationType === 'Common Area') {
      console.debug('blur locationTypeChanged' + this.locationType)
      this.hideShowMapFlag = true

      if (this.hideShowMapFlag == true) {
        this.hideShowMapText = 'Hide Map'
        this.locationTypeText = 'Location Type - Place marker on map'
      }
    } else {
      this.hideShowMapFlag = false

      if (this.hideShowMapFlag == false) {
        this.hideShowMapText = 'Show Map'
        this.locationTypeText = 'Location Type'
      }
    }
  },

  async toggleEditClose() {
    console.debug('in close...')
    this.category = this.workOrderType

    await this.refresh()
  },

  async categoryChanged() {
    console.debug('category changed...')
  },

  async showPhotoModal() {
    this.photoModalLoading = false
    this.photoModalOpen = true
  },
  async getCategories() {
    if (this.categories.length >= 1) {
      return
    }

    const { list, message, successful } = await getWorkOrderTypes()
    if (!successful) {
      console.error(message)
      return
    }
    this.categories = list.map(entry => ({
      value: entry.workOrderTypeID,
      label: entry.name
    }))

    this.categories = _orderBy(this.categories, 'label')
  },

  hideShowMap() {
    console.debug('in hideShowMap...')
    this.hideShowMapFlag = !this.hideShowMapFlag

    if (this.hideShowMapFlag == true) {
      this.hideShowMapText = 'Hide Map'
    } else {
      this.hideShowMapText = 'Show Map'
    }
  },

  async getParams() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    const ownerID = _get(this, ['$store', 'state', 'user', 'authUser', 'ownerID'], null)

    const { owner, exception } = await getOwner({
      ownerID
    })
    if (exception) {
      console.debug(exception)
      return
    }

    const sourceUnitID = _get(owner, ['unitID'], null)

    return {
      hoaID,
      sourceUnitID
    }
  },

  async erasePhoto({ details }) {
    this.loading = true

    const { message, successful } = await deleteWorkOrderRequestPhoto({
      id: details.id
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    for (let a = 0; a < this.photos.length; a++) {
      const id = _get(this, ['photos', a, 'id'], null)
      if (typeof id === 'number' && id === details.id) {
        this.photos.splice(a, 1)
      }
    }

    this.loading = false
  },

  async retrievePhotos({ photos }) {
    if (!Array.isArray(photos)) {
      return
    }

    const { problems, photos: photosContent, message } = await GetMultiplePhotos({
      paramsList: photos.map(photo => {
        const workOrderRequestPhotoID = _get(photo, ['workOrderRequestPhotoID'], '')

        return {
          workOrderRequestPhotoID,
          asBase64: true
        }
      })
    })
    if (problems !== 0) {
      notifyProblem(message)
      return
    }

    this.photos = photosContent
  },

  returnToParent() {
    this.$router.push({
      name: 'workOrderRequests'
    })
  },

  async populateLocationTypes() {
    await workOrderList.dispatch('loadLocationTypes').then(({ list }) => {
      if (list) {
        this.locationTypeList = list
      }
    })
  },

  async refresh() {
    const id = _get(this, ['$route', 'params', 'id'], '')

    await workOrderList
      .dispatch('getAccessToken', {
        credentialsName: 'google-maps-api-key'
      })
      .then(({ result }) => {
        if (result) {
          this.accessToken = result.credentialValue
        }
      })

    const { result, message, successful } = await getWorkOrderRequest({
      id
    })

    await this.populateLocationTypes()

    if (!successful) {
      this.retrieved = 2
      this.requestId = id
      this.loading = false

      notifyProblem(message)
      return
    }

    const status = _get(result, 'status', '')
    if (status === 'pending') {
      this.editable = true
    } else {
      this.editable = false

      if (status === 'cancelled') {
        this.rejectionReason = _get(result, 'rejectionReason', '')
      }
    }

    this.workOrderRequestID = _get(result, ['workOrderRequestID'], 0)
    this.workOrderTypeID = _get(result, ['workOrderTypeID'], 0)
    this.status = status
    this.retrieved = 1
    this.request = result
    const category = result.workOrderType.name || ''
    this.workOrderTypeID = _get(result, ['workOrderTypeID'], 0)
    this.workOrderType = category
    this.category = category
    this.summary = _get(result, ['description'], '')
    this.description = _get(result, ['detailedDescription'], '')
    this.locationType = _get(result, ['locationType'], '')
    this.latitude = _get(result, ['latitude'], 0)
    this.longitude = _get(result, ['longitude'], 0)

    if (this.isDebug === true) console.debug('lat/lng...' + this.latitude + ', ' + this.longitude)

    if (!this.latitude || this.latitude === 0) {
      if (this.isDebug === true)
        console.debug('setting default lat/lng...' + JSON.stringify(this.request))
      await this.loadHoaBoundsCoords()
      this.latitude = this.defaultCenterLat
      this.longitude = this.defaultCenterLng
    }

    await this.retrievePhotos({
      photos: _get(result, ['workOrderRequestPhotos'], [])
    })

    this.loading = false
  },

  async loadHoaBoundsCoords() {
    const { hoaID } = await this.getParams()

    await hoaUtility
      .dispatch('getHoaById', {
        hoaID: hoaID
      })
      .then(({ result }) => {
        if (
          result &&
          result.northmostLatitude &&
          result.southmostLatitude &&
          result.eastmostLongitude &&
          result.westmostLongitude
        ) {
          this.defaultCenterLat = (result.northmostLatitude + result.southmostLatitude) / 2
          this.defaultCenterLng = (result.eastmostLongitude + result.westmostLongitude) / 2
        }
      })
  },

  async reloadParentImages() {
    if (this.isDebug === true) console.debug('Homeowner edit reloadParentImages...')

    this.refresh()
  }
}
