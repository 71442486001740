<template>
  <PageContentLayoutOnly role="region" workorder-promote>
    <section class="section card">
      <div class="level pl-5 pr-5">
        <div class="pt-5 level-left level-item title">{{ $t('workorderrequest.title') }}</div>
        <div
          class="level-right"
          :style="{
            ...(breakpoint === 'desktop'
              ? {}
              : {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start'
                })
          }"
        >
          <div
            class="level-item"
            :style="{
              ...(breakpoint === 'desktop'
                ? {}
                : {
                    margin: 0
                  })
            }"
          >
            <svg
              width="30"
              height="30"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              ></path>
            </svg>
          </div>
          <div class="level-item">
            <b-button label="Back to Work Order Requests" @click.prevent="returnToParent()" />
          </div>
        </div>
      </div>

      <LinksMenu
        aria-label="work order details tabs"
        :tabs="tabs"
        prefix="workorderrequest."
        :style="{
          paddingLeft: '20px'
        }"
        :params="{
          id: $route.params.id
        }"
      >
        <section :style="{ overflowX: 'auto', flexGrow: 2 }">
          <SlideTransition :name="transitionName">
            <keep-alive>
              <router-view :key="$route.name" />
            </keep-alive>
          </SlideTransition>
        </section>
      </LinksMenu>
    </section>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import SlideTransition from '@/components/transitions/Slide.vue'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'

import LinksMenu from '@/components/menus/Links'

import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

export default {
  components: {
    LinksMenu,
    SlideTransition,
    PageContentLayoutOnly
  },

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      breakpoint: state => state.win.breakpoint
    }),

    debug() {
      return this.unit
    }
  },

  data,
  methods,
  watch,

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { workorderrequest: { title: 'Request' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em;
  }
}
</style>
