<template>
  <Panel :label="label" :fullWidth="fullWidth">
    <span
      multiline-label
      v-if="!editMode"
      :style="Object.assign({}, { display: 'inline-block' }, textStyles)"
      >{{ text }}</span
    >

    <b-input
      v-if="editMode"
      v-model="textData"
      :type="inputType"
      :maxlength="maxlength"
      :style="{
        ...(fullWidth
          ? {
              width: '100%'
            }
          : {})
      }"
    ></b-input>
  </Panel>
</template>

<script>
/*
  An editable MultiLine text input field
*/

/*
	import MultiLine from '@/components/Fields/MultiLine'

	<MultiLine 
    ref="multiline"
		label="Field"
		text="Field"

    // true -> the text is editable
    :editMode="true"
	/>

  const text = this.$refs.multiline.getValue ();
*/

import Panel from './../Panel'

export default {
  components: {
    Panel
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default() {
        return Infinity
      }
    },
    label: String,
    labelStyles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    text: [String, Number],
    textStyles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editMode: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String,
      default: 'textarea'
    }
  },

  methods: {
    getValue() {
      return this.textData
    }
  },

  data() {
    return {
      textData: this.text
    }
  },

  watch: {
    text(value) {
      this.textData = value
    }
  }
}
</script>
