export function data() {
  return {
    isDebug: true,
    requestId: '?',
    retrieved: 0,
    request: {},
    category: '',
    summary: '',
    latitude: null,
    longitude: null,
    locationType: '',
    categories: [],
    description: '',
    photos: [],
    defaultCenterLat: 0,
    defaultCenterLng: 0,
    workOrderRequestID: 0,
    workOrderTypeID: 0,
    workOrderType: '',
    accessToken: '',
    adderPhotos: [],
    locationTypeList: [],
    loading: true,
    rejectionReason: null,
    status: '?',
    editable: false,
    editMode: false,
    photoModalOpen: false,
    photoModalLoading: false,
    hideShowMapFlag: false,
    hideShowMapText: 'Show Map',
    locationTypeText: 'Location Type'
  }
}
