<template>
  <div
    :style="{
      display: 'flex',
      /* borderBottom: '1px solid #f4f2f2', */
      padding: '3px',
      alignItems: 'center',

      ...(fullWidth
        ? {
            width: '100%'
          }
        : {})
    }"
  >
    <b-field
      :label="label"
      :style="{
        ...(fullWidth
          ? {
              width: '100%'
            }
          : {})
      }"
    >
      <slot />
    </b-field>
  </div>
</template>

<script>
/*
  <Panel :label="label">


  </Panel />
*/

export default {
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    label: String
  }
}
</script>
