<template>
  <PageContent>
    <div
      class="card"
      :style="{
        padding: '20px',
        position: 'relative',
        marginTop: '30px'
      }"
    >
      <b-field label="Status"></b-field>
      <p>{{ status }}</p>

      <div
        v-if="typeof rejectionReason === 'string' && rejectionReason.length >= 1"
        :style="{ marginTop: '30px' }"
      >
        <b-field label="Reason"></b-field>
        <p>{{ rejectionReason }}</p>
      </div>
    </div>
    <div
      class="card"
      :style="{
        padding: '20px',
        position: 'relative',
        marginTop: '30px'
      }"
    >
      <div v-if="retrieved === 1">
        <button
          edit-request
          aria-label="open edit mode"
          class="icon-container"
          :style="{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: '10px',
            right: '10px',
            height: '40px',
            width: '40px'
          }"
          @click="toggleEdit"
        >
          <b-tooltip
            v-if="editable && editMode"
            label="Finish editing without saving"
            position="is-left"
            :style="{
              position: 'relative',
              height: '50%',
              width: '50%'
            }"
          >
            <div
              class="icon-x"
              :style="{
                position: 'relative',
                backgroundSize: 'cover',
                height: '100%',
                width: '100%'
              }"
              @click="toggleEditClose"
            ></div>
          </b-tooltip>

          <div
            v-if="editable && !editMode"
            class="icon-pencil"
            :style="{
              position: 'relative',
              backgroundSize: 'cover',
              height: '100%',
              width: '100%'
            }"
          ></div>
        </button>

        <b-button
          save-edits
          v-if="editMode"
          type="is-primary"
          @click="saveEdits"
          :style="{
            position: 'absolute',
            top: '10px',
            right: '60px'
          }"
          >Save</b-button
        >

        <div class="pt-5 pl-1" v-if="editMode">
          <b-field :label="locationTypeText">
            <b-select
              @click.native="locationTypeChanged()"
              v-model="locationType"
              required="true"
              placeholder="Select a Location Type"
            >
              <option v-for="option in locationTypeList" :value="option.value" :key="option.value">
                {{ option.label }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="pl-1" v-else>
          <b-field label="Location Type">{{ locationType || 'None' }}</b-field>
        </div>

        <div class="pt-5 pl-1" v-if="editMode">
          <b-field label="Category">
            <b-select v-model="category" required="true" placeholder="Select a Category">
              <option v-for="option in categories" :value="option" :key="option.value">
                {{ option.label }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="pt-5 pl-1" v-else>
          <b-field label="Category">{{ category || 'None' }}</b-field>
        </div>

        <div class="pt-5">
          <MultiLine
            inputType="text"
            summary-field
            ref="summary"
            label="Title"
            :text="summary"
            :style="{ marginBottom: '20px' }"
            :editMode="editMode"
            :fullWidth="true"
          />
        </div>

        <div class="">
          <MultiLine
            description-field
            ref="description"
            label="Description"
            :text="description"
            :style="{ marginBottom: '20px' }"
            :editMode="editMode"
            :fullWidth="true"
          />
        </div>

        <div @click="hideShowMap" style="cursor: pointer" class="href-overflow-wrap">
          {{ hideShowMapText }}
          <i style="font-size: 8pt; vertical-align: baseline !important;" class="icon-map" />
        </div>
        <div class="font-bold">
          Please drag and drop the pin to represent the location of the work order request.
        </div>
        <div
          class="pt-5 pb-5"
          v-if="hideShowMapFlag && (hideShowMapFlag === true || hideShowMapFlag === 'true')"
        >
          <GoogleMap
            mapStyle="satellite"
            :accessToken="accessToken"
            :longitude="Number.parseFloat(longitude && longitude ? longitude : 0)"
            :latitude="Number.parseFloat(latitude && latitude ? latitude : 0)"
            :zoomLevel="parseInt(19)"
            :workOrderRequestId="parseInt(workOrderRequestID)"
            :reloadParent="reloadParentImages"
          />
        </div>
      </div>

      <div not-found v-if="retrieved === 2">
        Work order request {{ requestId }} could not be found.
      </div>
    </div>

    <div
      v-if="retrieved === 1"
      class="card"
      :style="{
        padding: '20px',
        position: 'relative',
        marginTop: '30px'
      }"
    >
      <b-field label="Photos"></b-field>

      <div :style="{ marginBottom: '12px' }">
        <b-button v-if="editable" @click="showPhotoModal">Add Photos</b-button>
      </div>

      <div :style="{ display: 'flex', flexWrap: 'wrap' }">
        <div
          v-for="(entry, index) in photos"
          :key="index"
          :style="{ height: '31%', width: '31%', margin: '4px' }"
        >
          <PictureFrame
            :src="entry.photo"
            :details="entry"
            :erase="erasePhoto"
            :editMode="editable"
          />
        </div>
      </div>
    </div>

    <Modal :toggle.sync="photoModalOpen" :styles="{ modalContent: { height: '900px' } }">
      <template v-slot:header>Photo Adder</template>

      <template v-slot:footer>
        <b-button @click="addPhotos" type="is-primary" :style="{ float: 'right' }">Add</b-button>
      </template>

      <PhotoUploader ref="photoUploader" label="Photos" :update="adderPhotosUpdated" />

      <b-loading :is-full-page="false" v-model="photoModalLoading"></b-loading>
    </Modal>

    <b-loading :is-full-page="true" v-model="loading" :can-cancel="false"></b-loading>
  </PageContent>
</template>

<script>
//
import PageContent from '@/containers/PageContent'
import MultiLine from '@/components/Fields/MultiLine'
import PictureFrame from '@/components/Picture/Frame'
import Modal from '@/components/Modal'
import PhotoUploader from '@/components/Fields/PhotoUploader'
import GoogleMap from '@/components/googlemaps'
import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    PageContent,
    MultiLine,
    PictureFrame,
    Modal,
    PhotoUploader,
    GoogleMap
  },

  data,
  methods,

  mounted() {
    this.refresh()
  },

  i18n: {
    messages: {
      en: {
        workOrderRequestDetails: {
          title: 'Work Order Request'
        }
      }
    }
  }
}
</script>
